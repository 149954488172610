





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'

@Observer
@Component({
  components: {},
})
export default class CountDown extends Vue {
  @Prop() vm!: IdoApplyViewModel
}
